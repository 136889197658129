.Login[data-v-2a4033f6] {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 800px;
}
@media (min-width: 2560px) {
.Login[data-v-2a4033f6] {
    min-height: 1000px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login[data-v-2a4033f6] {
    min-height: 600px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login[data-v-2a4033f6] {
    min-height: 450px;
}
}
@media (max-width: 767px) {
.Login[data-v-2a4033f6] {
    min-height: 718px;
    padding: 76px 0 95px;
}
}
.Login-background[data-v-2a4033f6] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
.Login-background[data-v-2a4033f6] {
    position: fixed;
    width: 100vw;
    height: 100vh;
}
}
.Login-background img[data-v-2a4033f6] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Login-logo[data-v-2a4033f6] {
  position: absolute;
  left: 40px;
  top: 16px;
}
.Login-logo a[data-v-2a4033f6] {
  display: inline-block;
}
@media (min-width: 2560px) {
.Login-logo[data-v-2a4033f6] {
    top: 24px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-logo[data-v-2a4033f6] {
    top: 12px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-logo[data-v-2a4033f6] {
    left: 28px;
    top: 8px;
}
}
@media (max-width: 767px) {
.Login-logo[data-v-2a4033f6] {
    left: 24px;
}
}
.Login-content[data-v-2a4033f6] {
  position: relative;
  width: 680px;
  text-align: center;
}
@media (min-width: 2560px) {
.Login-content[data-v-2a4033f6] {
    width: 920px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-content[data-v-2a4033f6] {
    width: 504px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-content[data-v-2a4033f6] {
    width: 360px;
}
}
@media (max-width: 767px) {
.Login-content[data-v-2a4033f6] {
    width: auto;
}
}
.Login-info[data-v-2a4033f6] {
  margin-bottom: 40px;
}
@media (min-width: 2560px) {
.Login-info[data-v-2a4033f6] {
    margin-bottom: 48px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-info[data-v-2a4033f6] {
    margin-bottom: 32px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-info[data-v-2a4033f6] {
    margin-bottom: 24px;
}
}
@media (max-width: 767px) {
.Login-info[data-v-2a4033f6] {
    margin: 0 24px 24px;
}
}
.Login-title[data-v-2a4033f6] {
  margin-bottom: 8px;
}
@media (min-width: 2560px) {
.Login-title[data-v-2a4033f6] {
    margin-bottom: 16px;
}
}
@media (min-width: 768px) and (max-width: 1365px), (max-width: 767px) {
.Login-title[data-v-2a4033f6] {
    margin-bottom: 4px;
}
}
.Login-form[data-v-2a4033f6] {
  display: flex;
  flex-flow: column;
  padding: 40px;
  background: #E9E9EC;
  border-radius: 8px;
}
@media (min-width: 2560px) {
.Login-form[data-v-2a4033f6] {
    padding: 56px;
    border-radius: 10px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-form[data-v-2a4033f6] {
    padding: 32px;
    border-radius: 6px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-form[data-v-2a4033f6] {
    padding: 24px;
    border-radius: 4px;
}
}
@media (max-width: 767px) {
.Login-form[data-v-2a4033f6] {
    padding: 20px;
    margin: 0 4px;
}
}
.Login-additional[data-v-2a4033f6] {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
@media (min-width: 2560px) {
.Login-additional[data-v-2a4033f6] {
    margin-top: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-additional[data-v-2a4033f6] {
    margin-top: 12px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-additional[data-v-2a4033f6] {
    margin-top: 8px;
}
}
@media (max-width: 767px) {
.Login-additional[data-v-2a4033f6] {
    height: 68px;
    flex-flow: column-reverse;
    justify-content: space-between;
}
}
.Login-error[data-v-2a4033f6] {
  color: #C13737;
}
@media (min-width: 768px) {
.Login-error[data-v-2a4033f6] {
    justify-self: flex-start;
    margin-right: auto;
}
}
.Login-passwordReset[data-v-2a4033f6] {
  color: #464646;
  text-align: right;
}
@media (max-width: 767px) {
.Login-passwordReset[data-v-2a4033f6] {
    justify-self: flex-start;
    margin-bottom: auto;
}
}
.Login-submit[data-v-2a4033f6] {
  width: 100%;
  padding: 10px;
  border-radius: 70px;
  margin: 48px 0 23px;
}
@media (min-width: 2560px) {
.Login-submit[data-v-2a4033f6] {
    padding: 18px;
    border-radius: 96px;
    margin: 72px 0 23px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-submit[data-v-2a4033f6] {
    padding: 8px;
    border-radius: 56px;
    margin: 44px 0 16px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-submit[data-v-2a4033f6] {
    padding: 6px;
    border-radius: 40px;
    margin: 32px 0 16px;
}
}
@media (max-width: 767px) {
.Login-submit[data-v-2a4033f6] {
    padding: 8px;
    margin: 16px 0 16px;
}
}
.Login-social[data-v-2a4033f6] {
  display: flex;
}
.Login-social button[data-v-2a4033f6] {
  flex: 1;
  height: 44px;
  background: #D7D7DC;
  border-radius: 70px;
}
@media (min-width: 2560px) {
.Login-social button[data-v-2a4033f6] {
    height: 60px;
    border-radius: 96px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-social button[data-v-2a4033f6] {
    height: 32px;
    border-radius: 56px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-social button[data-v-2a4033f6] {
    height: 24px;
    border-radius: 40px;
}
}
@media (max-width: 767px) {
.Login-social button[data-v-2a4033f6] {
    height: 36px;
}
}
.Login-social button[data-v-2a4033f6]:not(:last-child) {
  margin-right: 9px;
}
@media (max-width: 767px) {
.Login-social button[data-v-2a4033f6]:not(:last-child) {
    margin-right: 6px;
}
}
.Login-footer[data-v-2a4033f6] {
  position: absolute;
  left: 0;
  bottom: 24px;
  width: 100%;
  padding: 24px 40px 0;
  border-top: 1px solid #464646;
}
@media (min-width: 2560px) {
.Login-footer[data-v-2a4033f6] {
    bottom: 32px;
    padding-top: 32px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-footer[data-v-2a4033f6] {
    bottom: 20px;
    padding-top: 20px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-footer[data-v-2a4033f6] {
    bottom: 16px;
    padding: 16px 28px 0;
}
}
@media (max-width: 767px) {
.Login-footer[data-v-2a4033f6] {
    bottom: 40px;
    padding: 16px 24px 0;
}
}
.Login-footerContent[data-v-2a4033f6] {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media (max-width: 767px) {
.Login-footerContent[data-v-2a4033f6] {
    display: block;
}
}
@media (min-width: 768px) {
.Login-footer a[data-v-2a4033f6]:not(:first-child) {
    margin-left: 48px;
}
}
@media (min-width: 2560px) {
.Login-footer a[data-v-2a4033f6]:not(:first-child) {
    margin-left: 64px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-footer a[data-v-2a4033f6]:not(:first-child) {
    margin-left: 32px;
}
}
@media (max-width: 767px) {
.Login-footer a[data-v-2a4033f6] {
    display: block;
}
}
.Login-unti[data-v-2a4033f6] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login-unti span[data-v-2a4033f6] {
  color: black;
}
@media (min-width: 2560px) {
.Login-unti img[data-v-2a4033f6] {
    width: 44px;
    height: 44px;
    margin-left: 12px;
}
}
@media (min-width: 1920px) and (max-width: 2559px) {
.Login-unti img[data-v-2a4033f6] {
    width: 36px;
    height: 36px;
    margin-left: 10px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Login-unti img[data-v-2a4033f6] {
    width: 32px;
    height: 32px;
    margin-left: 10px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Login-unti img[data-v-2a4033f6] {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}
}
@media (max-width: 767px) {
.Login-unti img[data-v-2a4033f6] {
    width: 32px;
    height: 32px;
    margin-left: 8px;
}
}

.Logo {
  display: flex;
  align-items: center;
}
.Logo--dark .Logo-circle {
  background: #17161B;
}
.Logo--dark .Logo-title {
  color: #17161B;
}
@media (max-width: 767px) {
.Logo.isMobileDark .Logo-circle {
    background: #17161B;
}
.Logo.isMobileDark .Logo-title {
    color: #17161B;
}
}
.Logo-circle {
  width: 36px;
  height: 36px;
  margin-right: 11px;
  background: #E9E9EC;
  border-radius: 50%;
}
@media (min-width: 2560px) {
.Logo-circle {
    width: 40px;
    height: 40px;
    margin-right: 11px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Logo-circle {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Logo-circle {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
}
@media (max-width: 767px) {
.Logo-circle {
    width: 36px;
    height: 36px;
}
}
.Logo-title {
  width: 90px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}
@media (min-width: 2560px) {
.Logo-title {
    width: 120px;
    font-size: 32px;
    line-height: 32px;
}
}
@media (min-width: 1920px) and (max-width: 2559px) {
.Logo-title {
    width: 120px;
    font-size: 28px;
    line-height: 28px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Logo-title {
    width: 66px;
    font-size: 26px;
    line-height: 26px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Logo-title {
    width: 50px;
    font-size: 20px;
    line-height: 20px;
}
}
@media (max-width: 767px) {
.Logo-title {
    display: none;
}
}

.Recovery[data-v-9591b726] {
  width: 100%;
  height: 100vh;
  min-height: 600px;
}
@media (max-width: 767px) {
.Recovery[data-v-9591b726] {
    min-height: 500px;
}
}
.Recovery-nav[data-v-9591b726] {
  position: fixed;
  left: 40px;
  top: 0;
  width: 290px;
  height: 100vh;
  padding-top: 16px;
  border-right: 1px solid #D7D7DC;
}
@media (min-width: 2560px) {
.Recovery-nav[data-v-9591b726] {
    width: 400px;
    padding-top: 24px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-nav[data-v-9591b726] {
    width: 210px;
    padding-top: 12px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-nav[data-v-9591b726] {
    left: 28px;
    width: 148px;
    padding-top: 8px;
}
}
@media (max-width: 767px) {
.Recovery-nav[data-v-9591b726] {
    width: auto;
    height: auto;
    left: 24px;
    border-right: none;
}
}
.Recovery-logo[data-v-9591b726] {
  display: block;
  margin-bottom: 44px;
}
@media (min-width: 2560px) {
.Recovery-logo[data-v-9591b726] {
    margin-bottom: 56px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-logo[data-v-9591b726] {
    margin-bottom: 36px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-logo[data-v-9591b726] {
    margin-bottom: 24px;
}
}
@media (max-width: 767px) {
.Recovery-logo[data-v-9591b726] {
    margin-bottom: 0;
}
}
@media (max-width: 767px) {
.Recovery-back[data-v-9591b726] {
    display: none;
}
}
.Recovery-content[data-v-9591b726] {
  position: relative;
  height: 100%;
  margin-left: 330px;
  padding: 104px 330px 0;
}
@media (min-width: 2560px) {
.Recovery-content[data-v-9591b726] {
    margin-left: 436px;
    padding: 140px 436px 0;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-content[data-v-9591b726] {
    margin-left: 250px;
    padding: 80px 250px 0;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-content[data-v-9591b726] {
    margin-left: 180px;
    padding: 56px 192px 0;
}
}
@media (max-width: 767px) {
.Recovery-content[data-v-9591b726] {
    margin: 0 24px;
    padding: 76px 0 36px;
}
}
.Recovery-contentInner[data-v-9591b726] {
  position: relative;
  height: 100%;
  max-width: 910px;
  margin: 0 auto;
}
.Recovery-info[data-v-9591b726] {
  color: #17161B;
  padding-bottom: 40px;
  margin-bottom: 28px;
  border-bottom: 1px solid #D7D7DC;
}
@media (min-width: 2560px) {
.Recovery-info[data-v-9591b726] {
    padding-bottom: 56px;
    margin-bottom: 40px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-info[data-v-9591b726] {
    padding-bottom: 32px;
    margin-bottom: 20px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-info[data-v-9591b726] {
    padding-bottom: 24px;
    margin-bottom: 16px;
}
}
@media (max-width: 767px) {
.Recovery-info[data-v-9591b726] {
    padding-bottom: 24px;
    margin-bottom: 20px;
}
}
.Recovery-title[data-v-9591b726] {
  margin-bottom: 12px;
}
@media (min-width: 2560px) {
.Recovery-title[data-v-9591b726] {
    margin-bottom: 16px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-title[data-v-9591b726] {
    margin-bottom: 8px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-title[data-v-9591b726] {
    margin-bottom: 4px;
}
}
@media (max-width: 767px) {
.Recovery-title[data-v-9591b726] {
    margin-bottom: 4px;
}
}
@media (min-width: 2560px) {
.Recovery-description[data-v-9591b726] {
    max-width: 808px;
}
}
@media (min-width: 1920px) and (max-width: 2559px) {
.Recovery-description[data-v-9591b726] {
    max-width: 600px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-description[data-v-9591b726] {
    max-width: 440px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-description[data-v-9591b726] {
    max-width: 312px;
}
}
.Recovery-bottom[data-v-9591b726] {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 36px;
}
@media (min-width: 2560px) {
.Recovery-bottom[data-v-9591b726] {
    bottom: 56px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Recovery-bottom[data-v-9591b726] {
    bottom: 32px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Recovery-bottom[data-v-9591b726] {
    bottom: 24px;
}
}
@media (max-width: 767px) {
.Recovery-bottom[data-v-9591b726] {
    display: block;
    width: 100%;
}
}
.Recovery-note[data-v-9591b726] {
  color: #17161B;
}
.Recovery-note.isError[data-v-9591b726] {
  color: #C13737;
}
@media (max-width: 767px) {
.Recovery-note[data-v-9591b726] {
    text-align: center;
}
}

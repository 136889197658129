@font-face {
  font-family: 'Suisse';
  src: url('/static/fonts/SuisseIntl-Regular.woff2') format('woff2'), url('/static/fonts/SuisseIntl-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Suisse';
  src: url('/static/fonts/SuisseIntl-Book.woff2') format('woff2'), url('/static/fonts/SuisseIntl-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  color: #E9E9EC;
  font-family: 'Suisse', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-font-feature-settings: "kern" 1;
  font-feature-settings: "kern";
}
button,
input,
textarea {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  color: inherit;
  font: inherit;
}
button:disabled,
input:disabled,
textarea:disabled {
  opacity: 1;
}
button {
  cursor: pointer;
  transition: opacity, color;
  transition-duration: 0.2s;
}
@media (min-width: 768px) {
  button:hover {
    opacity: 0.5;
  }
}
button.isActive {
  pointer-events: none;
}
a {
  text-decoration: none;
  transition: opacity, color;
  transition-duration: 0.2s;
  cursor: pointer;
}
a:not(.PageLink) {
  color: inherit;
}
@media (min-width: 768px) {
  a:hover {
    opacity: 0.5;
  }
}
svg {
  width: 100%;
  height: 100%;
}
.s-text-caption--small {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
}
@media (min-width: 2560px) {
  .s-text-caption--small {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-caption--small {
    font-size: 8px;
    line-height: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-caption--small {
    font-size: 6px;
    line-height: 8px;
  }
}
.s-text-caption {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}
@media (min-width: 2560px) {
  .s-text-caption {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-caption {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-caption {
    font-size: 8px;
    line-height: 12px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-text-caption {
    font-size: 13px;
    line-height: 20px;
  }
}
.s-text-label {
  font-size: 22px;
  line-height: 28px;
}
@media (min-width: 2560px) {
  .s-text-label {
    font-size: 28px;
    line-height: 36px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-label {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-label {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-text-label {
    font-size: 13px;
    line-height: 20px;
  }
}
.s-text-common {
  font-size: 22px;
  line-height: 28px;
}
@media (min-width: 2560px) {
  .s-text-common {
    font-size: 28px;
    line-height: 36px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-common {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-common {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-text-common {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-text-common a {
  color: #8D8D8F;
}
.s-text-common p,
.s-text-common ul,
.s-text-common ol {
  margin: 28px 0 0;
}
@media (min-width: 2560px) {
  .s-text-common p,
  .s-text-common ul,
  .s-text-common ol {
    margin-top: 36px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-common p,
  .s-text-common ul,
  .s-text-common ol {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-common p,
  .s-text-common ul,
  .s-text-common ol {
    margin-top: 16px;
  }
}
.s-text-common p:first-child,
.s-text-common ul:first-child,
.s-text-common ol:first-child {
  margin-top: 0;
}
.s-text-common p + ol,
.s-text-common p + ul {
  margin-top: 0;
}
.s-text-common ol,
.s-text-common ul {
  list-style: none;
  padding: 0;
}
.s-text-common ol li,
.s-text-common ul li {
  position: relative;
  padding-left: 36px;
}
@media (min-width: 2560px) {
  .s-text-common ol li,
  .s-text-common ul li {
    padding-left: 50px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-common ol li,
  .s-text-common ul li {
    padding-left: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-common ol li,
  .s-text-common ul li {
    padding-left: 16px;
  }
}
@media (max-width: 767px) {
  .s-text-common ol li,
  .s-text-common ul li {
    padding-left: 24px;
  }
}
.s-text-common ol li:before,
.s-text-common ul li:before {
  position: absolute;
  left: 0;
  top: 0;
}
.s-text-common ol li.ql-indent-1,
.s-text-common ul li.ql-indent-1 {
  margin-left: 40px;
  padding-left: 32px;
}
@media (min-width: 2560px) {
  .s-text-common ol li.ql-indent-1,
  .s-text-common ul li.ql-indent-1 {
    margin-left: 50px;
    padding-left: 50px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-common ol li.ql-indent-1,
  .s-text-common ul li.ql-indent-1 {
    margin-left: 26px;
    padding-left: 28px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-common ol li.ql-indent-1,
  .s-text-common ul li.ql-indent-1 {
    margin-left: 16px;
    padding-left: 16px;
  }
}
@media (max-width: 767px) {
  .s-text-common ol li.ql-indent-1,
  .s-text-common ul li.ql-indent-1 {
    margin-left: 24px;
    padding-left: 24px;
  }
}
.s-text-common ol {
  counter-reset: ol-counter;
}
.s-text-common ol > li:not(.ql-indent-1) {
  counter-increment: ol-counter;
}
.s-text-common ol > li:not(.ql-indent-1):before {
  content: counter(ol-counter) '.';
}
.s-text-common ol > li.ql-indent-1:before {
  content: '–';
}
.s-text-common ul li:before {
  content: '–';
}
.s-text-title {
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.02em;
}
@media (min-width: 2560px) {
  .s-text-title {
    font-size: 56px;
    line-height: 60px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-text-title {
    font-size: 22px;
    line-height: 28px;
  }
}
.s-text-title--big {
  font-size: 56px;
  line-height: 60px;
  letter-spacing: -0.02em;
}
@media (min-width: 2560px) {
  .s-text-title--big {
    font-size: 72px;
    line-height: 80px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-text-title--big {
    font-size: 40px;
    line-height: 44px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-text-title--big {
    font-size: 28px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-text-title--big {
    font-size: 32px;
    line-height: 36px;
    word-wrap: break-word;
  }
}
.s-button--primary {
  position: relative;
  background: #17161B;
  color: #E9E9EC;
  text-align: center;
  min-width: 64px;
}
a.s-button--primary {
  color: #E9E9EC;
}
.s-button--primary:disabled,
.s-button--primary.isDisabled {
  pointer-events: none;
  background: #8D8D8F;
  color: #464646;
}
@media (min-width: 2560px) {
  .s-button--primary {
    min-width: 84px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-button--primary {
    min-width: 48px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--primary {
    min-width: 36px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--primary {
    min-width: 48px;
  }
}
.s-button--primary svg {
  width: 16px;
  height: 16px;
  fill: #E9E9EC;
}
@media (min-width: 2560px) {
  .s-button--primary svg {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-button--primary svg {
    width: 12px;
    height: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--primary svg {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--primary svg {
    width: 12px;
    height: 12px;
  }
}
.s-button--primary svg:not(:last-child) {
  margin-right: 8px;
}
@media (min-width: 2560px) {
  .s-button--primary svg:not(:last-child) {
    margin-right: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--primary svg:not(:last-child) {
    margin-right: 4px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--primary svg:not(:last-child) {
    margin-right: 3px;
  }
}
.s-button--secondary {
  background: #E9E9EC;
  color: #17161B;
  text-align: center;
}
.s-button--secondary:disabled {
  pointer-events: none;
  background: #8D8D8F;
  color: #464646;
}
.s-button--accent {
  background: #E9E9EC;
  color: #17161B;
  text-align: center;
  background: #B8C3C7;
}
.s-button--accent:disabled {
  pointer-events: none;
  background: #8D8D8F;
  color: #464646;
}
.s-button--transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #464646;
  color: #E9E9EC;
  padding: 10px 0;
  text-align: center;
}
@media (min-width: 2560px) {
  .s-button--transparent {
    padding: 18px 0;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-button--transparent {
    padding: 8px 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--transparent {
    padding: 6px 0;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--transparent {
    padding: 8px 0;
  }
}
.s-button--transparent svg {
  width: 16px;
  height: 16px;
  fill: #E9E9EC;
}
@media (min-width: 2560px) {
  .s-button--transparent svg {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-button--transparent svg {
    width: 12px;
    height: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--transparent svg {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--transparent svg {
    width: 12px;
    height: 12px;
  }
}
.s-button--transparent svg:not(:last-child) {
  margin-right: 8px;
}
@media (min-width: 2560px) {
  .s-button--transparent svg:not(:last-child) {
    margin-right: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-button--transparent svg:not(:last-child) {
    margin-right: 4px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-button--transparent svg:not(:last-child) {
    margin-right: 3px;
  }
}
.s-button-icon {
  width: 24px;
  height: 24px;
  fill: #17161B;
}
@media (max-width: 767px) {
  .isResizable .s-button-icon {
    width: 20px;
    height: 20px;
  }
}
.s-scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.s-scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.s-form-title {
  color: #17161B;
  margin-bottom: 56px;
}
@media (min-width: 2560px) {
  .s-form-title {
    margin-bottom: 72px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-title {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-title {
    margin-bottom: 28px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-form-title {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 40px;
  }
}
.s-form-blocks:not(:last-child) {
  margin-bottom: 40px;
}
@media (min-width: 2560px) {
  .s-form-blocks:not(:last-child) {
    margin-bottom: 56px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-blocks:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-blocks:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .s-form-blocks:not(:last-child) {
    margin-bottom: 20px;
  }
}
.s-form-block:not(:last-child) {
  margin-bottom: 40px;
}
@media (min-width: 2560px) {
  .s-form-block:not(:last-child) {
    margin-bottom: 56px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-block:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-block:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .s-form-block:not(:last-child) {
    margin-bottom: 20px;
  }
}
.s-form-subtitle {
  display: flex;
  color: #464646;
  padding-bottom: 16px;
  border-bottom: 1px solid #D7D7DC;
}
@media (min-width: 2560px) {
  .s-form-subtitle {
    padding-bottom: 20px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-subtitle {
    padding-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-subtitle {
    padding-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .s-form-subtitle {
    color: #17161B;
    padding-bottom: 8px;
  }
}
.s-form-subtitle div:first-child {
  width: 295px;
}
@media (min-width: 2560px) {
  .s-form-subtitle div:first-child {
    width: 205px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-subtitle div:first-child {
    width: 225px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-subtitle div:first-child {
    width: 139px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-form-subtitle div:first-child {
    width: 100%;
  }
}
.s-form-subtitle div:not(:first-child) {
  flex: 1;
  margin-left: 5px;
}
.s-form-submit {
  width: 290px;
  padding: 20px;
  border-radius: 50px;
  margin-right: 20px;
}
@media (min-width: 2560px) {
  .s-form-submit {
    width: 390px;
    padding: 28px;
    border-radius: 64px;
    margin-right: 27px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form-submit {
    width: 210px;
    padding: 16px;
    border-radius: 38px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form-submit {
    width: 148px;
    padding: 11px;
    border-radius: 32px;
    margin-right: 16px;
  }
}
@media (max-width: 767px) {
  .s-form-submit {
    width: 100%;
    padding: 14px;
    margin: 0 0 8px;
  }
}
.s-form .ImageUpload:not(:first-child),
.s-form .Input:not(:first-child),
.s-form .Textarea:not(:first-child),
.s-form .s-form-item:not(:first-child) {
  margin-top: 28px;
}
@media (min-width: 2560px) {
  .s-form .ImageUpload:not(:first-child),
  .s-form .Input:not(:first-child),
  .s-form .Textarea:not(:first-child),
  .s-form .s-form-item:not(:first-child) {
    margin-top: 40px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-form .ImageUpload:not(:first-child),
  .s-form .Input:not(:first-child),
  .s-form .Textarea:not(:first-child),
  .s-form .s-form-item:not(:first-child) {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-form .ImageUpload:not(:first-child),
  .s-form .Input:not(:first-child),
  .s-form .Textarea:not(:first-child),
  .s-form .s-form-item:not(:first-child) {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .isResizable .s-form .ImageUpload:not(:first-child),
  .isResizable .s-form .Input:not(:first-child),
  .isResizable .s-form .Textarea:not(:first-child),
  .isResizable .s-form .s-form-item:not(:first-child) {
    margin-top: 20px;
  }
}
.s-hoverable {
  cursor: pointer;
  transition: opacity, color;
  transition-duration: 0.2s;
}
@media (min-width: 768px) {
  .s-hoverable:hover {
    opacity: 0.5;
  }
}
.isNotResizable .s-columns {
  display: flex;
}
.isNotResizable .s-columns > div:first-child {
  width: 600px;
  margin-right: 20px;
}
.isNotResizable .s-columns > div:last-child {
  flex: 1;
  max-width: calc(100% - 620px);
}
@media (min-width: 768px) {
  .s-columns {
    display: flex;
  }
}
@media (min-width: 2560px) {
  .s-columns > div:first-child {
    width: 808px;
    margin-right: 28px;
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .s-columns > div:first-child {
    width: 600px;
    margin-right: 20px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-columns > div:first-child {
    width: 440px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-columns > div:first-child {
    width: 312px;
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  .s-columns > div:last-child {
    flex: 1;
  }
}
@media (min-width: 2560px) {
  .s-columns > div:last-child {
    max-width: calc(100% - 836px);
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .s-columns > div:last-child {
    flex: 1;
    max-width: calc(100% - 620px);
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-columns > div:last-child {
    max-width: calc(100% - 460px);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-columns > div:last-child {
    max-width: calc(100% - 328px);
  }
}
@media (min-width: 768px) {
  .s-page {
    min-height: 100vh;
  }
}
.s-block {
  display: flex;
}
.s-block:not(:last-child) {
  margin-bottom: 28px;
}
@media (min-width: 2560px) {
  .s-block:not(:last-child) {
    margin-bottom: 36px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-block:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-block:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .s-block:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .s-block {
    display: block;
  }
}
.s-block > div:first-child {
  width: 290px;
  margin-right: 20px;
}
@media (min-width: 2560px) {
  .s-block > div:first-child {
    width: 390px;
    margin-right: 28px;
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-block > div:first-child {
    width: 210px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-block > div:first-child {
    width: 148px;
    margin-right: 16px;
  }
}
@media (max-width: 767px) {
  .s-block > div:first-child {
    width: 100%;
    margin: 0 0 16px;
  }
}
.s-block > div:nth-child(2) {
  flex: 1;
  max-width: calc(100% - 310px);
  overflow: hidden;
}
@media (min-width: 2560px) {
  .s-block > div:nth-child(2) {
    max-width: calc(100% - 418px);
  }
}
@media (min-width: 1366px) and (max-width: 1919px) {
  .s-block > div:nth-child(2) {
    max-width: calc(100% - 230px);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .s-block > div:nth-child(2) {
    max-width: calc(100% - 164px);
  }
}
@media (max-width: 767px) {
  .s-block > div:nth-child(2) {
    max-width: 100%;
  }
}
.s-scroll-prevent {
  position: fixed;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 767px) {
  html body .PageWrapper .s-desktop {
    display: none;
  }
}
@media (min-width: 768px) {
  html body .PageWrapper .s-phone {
    display: none;
  }
}
.s-textarea-wrapper {
  display: grid;
  overflow: hidden;
}
.s-textarea-wrapper::after {
  /* Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.s-textarea-wrapper textarea,
.s-textarea-wrapper::after {
  /* Identical styling required!! */
  padding: 0;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
.s-textarea {
  resize: none;
  padding: 0;
  width: 100%;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translate3d(0, 100%, 0);
}
.slide-top-enter-active,
.slide-top-leave-active {
  transition: transform 0.2s;
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translate3d(0, 100%, 0);
}

.Input[data-v-b62a8312] {
  padding-bottom: 28px;
  border-bottom: 1px solid #D7D7DC;
  text-align: left;
}
@media (min-width: 2560px) {
.Input[data-v-b62a8312] {
    padding-bottom: 40px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Input[data-v-b62a8312] {
    padding-bottom: 20px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Input[data-v-b62a8312] {
    padding-bottom: 16px;
}
}
@media (max-width: 767px) {
.isResizable .Input[data-v-b62a8312] {
    padding-bottom: 20px;
}
}
.Input-content[data-v-b62a8312] {
  position: relative;
  display: flex;
}
@media (max-width: 767px) {
.isResizable .Input-content[data-v-b62a8312] {
    flex-flow: column;
}
}
.Input label[data-v-b62a8312] {
  width: 150px;
  margin-right: 5px;
  color: #464646;
}
@media (min-width: 2560px) {
.Input label[data-v-b62a8312] {
    width: 205px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Input label[data-v-b62a8312] {
    width: 106px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Input label[data-v-b62a8312] {
    width: 73px;
}
}
@media (max-width: 767px) {
.isResizable .Input label[data-v-b62a8312] {
    margin-bottom: 4px;
    color: #17161B;
}
}
.Input input[data-v-b62a8312] {
  flex: 1;
  color: #17161B;
}
.Input input[data-v-b62a8312]::-moz-placeholder {
  color: #8D8D8F;
  opacity: 1;
}
.Input input[data-v-b62a8312]::placeholder {
  color: #8D8D8F;
  opacity: 1;
}
.Input-button[data-v-b62a8312] {
  position: absolute;
  top: 50%;
  right: 0;
  margin: auto 0;
  transform: translateY(-50%);
}
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
  padding: 10px 24px;
  border: 1px solid #D7D7DC;
  border-radius: 50px;
  color: #17161B;
}
@media (max-width: 767px) {
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
    padding: 8px 20px 8px 16px;
    border-radius: 40px;
}
}
@media (min-width: 2560px) {
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
    padding: 16px 32px;
    border-radius: 64px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
    padding: 8px 16px;
    border-radius: 40px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
    padding: 6px 12px;
    border-radius: 28px;
}
}
@media (max-width: 767px) {
.Input-button[data-v-b62a8312]:not(.Input-button--password) {
    padding: 8px 20px;
}
}
.Input-button--password[data-v-b62a8312] {
  width: 24px;
  height: 24px;
}
@media (min-width: 2560px) {
.Input-button--password[data-v-b62a8312] {
    width: 32px;
    height: 32px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Input-button--password[data-v-b62a8312] {
    width: 18px;
    height: 18px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Input-button--password[data-v-b62a8312] {
    width: 12px;
    height: 12px;
}
}
@media (max-width: 767px) {
.isResizable .Input-button--password[data-v-b62a8312] {
    width: 16px;
    height: 16px;
    top: auto;
    top: initial;
    bottom: 4px;
    transform: none;
}
}
.Input-button--password svg[data-v-b62a8312] {
  fill: #17161B;
}
.Input--big label[data-v-b62a8312] {
  width: 295px;
}
@media (min-width: 2560px) {
.Input--big label[data-v-b62a8312] {
    width: 205px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.Input--big label[data-v-b62a8312] {
    width: 225px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.Input--big label[data-v-b62a8312] {
    width: 139px;
}
}
@media (max-width: 767px) {
.isResizable .Input--big label[data-v-b62a8312] {
    width: 100%;
}
}
.Input--light[data-v-b62a8312] {
  border-bottom-color: #464646;
}
.Input--light input[data-v-b62a8312] {
  color: #E9E9EC;
}
.Input--light input[data-v-b62a8312]::-moz-placeholder {
  color: #464646;
}
.Input--light input[data-v-b62a8312]::placeholder {
  color: #464646;
}

.PageLink {
  display: flex;
  align-items: flex-start;
  color: #17161B;
}
.PageLink-icon {
  display: flex;
  align-items: center;
  height: 28px;
}
@media (min-width: 2560px) {
.PageLink-icon {
    height: 36px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.PageLink-icon {
    height: 20px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.PageLink-icon {
    height: 16px;
}
}
@media (max-width: 767px) {
.isResizable .PageLink-icon {
    height: 20px;
}
}
.PageLink-icon svg {
  width: 11px;
  height: 9px;
  margin-right: 19px;
  fill: #17161B;
  transform: rotate(180deg);
}
@media (min-width: 2560px) {
.PageLink-icon svg {
    width: 16px;
    height: 13px;
    margin-right: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1919px) {
.PageLink-icon svg {
    width: 9px;
    height: 8px;
    margin-right: 10px;
}
}
@media (min-width: 768px) and (max-width: 1365px) {
.PageLink-icon svg {
    width: 6px;
    height: 6px;
    margin-right: 7px;
}
}
@media (max-width: 767px) {
.PageLink-icon svg {
    width: 9px;
    height: 8px;
    margin-right: 6px;
}
}
.PageLink--light {
  color: #E9E9EC;
}
.PageLink--light svg {
  fill: #E9E9EC;
}
.PageLink--forward {
  flex-flow: row-reverse;
}
.PageLink--forward .PageLink-icon {
  transform: rotate(180deg);
}
